import "@equipmentshare/ds2/dist/assets/ds2.css";

import { useIdentity } from "@equipmentshare/auth0-react";
import { EuiProvider } from "@equipmentshare/ds2";
import { withLDProvider } from "launchdarkly-react-client-sdk";
import { type ReactNode } from "react";

import { FlyoutProvider } from "./components/flyout-form";
import { ToastProvider } from "./components/toasts";

export type FleetConfigurationComponentProviderConfig = {
  flyout: {
    keys: string[];
  };
};

export function FleetConfigurationComponentProvider(props: {
  children: ReactNode;
  config: FleetConfigurationComponentProviderConfig;
}) {
  return (
    <FlyoutProvider keys={props.config.flyout.keys}>
      <FeatureFlagProvider>
        <EuiProvider>
          <ToastProvider>{props.children}</ToastProvider>
        </EuiProvider>
      </FeatureFlagProvider>
    </FlyoutProvider>
  );
}

export const FeatureFlagProvider = ({ children }: { children: ReactNode }) => {
  const { companyId, userId, fullName, email } = useIdentity();

  const LDProvider = withLDProvider({
    clientSideID: process.env.NEXT_PUBLIC_LAUNCHDARKLY_CLIENT_SIDE_ID ?? "",
    context: {
      kind: "user",
      key: String(userId),
      name: String(fullName),
      email: String(email),
      companyId: String(companyId),
    },
  })(() => children);

  return <LDProvider />;
};
