import { Environment, getEnvUrl } from "../../api";
import { STRING_REPLACE_IDENTIFIER } from "../../transformations";

export const LOCAL_T3 = "http://localhost:8000" as const;

export const TRACKER_INSTALL_GUIDE = {
  FJ2500:
    "https://help.estrack.com/en/articles/8436714-telematics-device-pui-fj2500-installation-guide",
  GL520:
    "https://help.estrack.com/en/articles/5345060-telematics-device-queclink-gl500-installation-guide",
  MC4_PLUS:
    "https://help.estrack.com/en/articles/3056073-telematics-device-mc4-installation",
  MCX101:
    "https://help.estrack.com/en/articles/8314043-telematics-device-morey-mcx101-installation-guide",
  T3_DASH_CAM:
    "https://drive.google.com/file/d/1xqt4uXnSbpxdCKRQpAbAeIO06IKUOizD/view",
} as const;

export const EXTERNAL_URLS = {
  T3:
    process.env.NEXT_PUBLIC_FLEET_CONFIGURATION_ENVIRONMENT ===
    Environment.Development
      ? LOCAL_T3
      : getEnvUrl("app.estrack.com"),
  T3_PERMISSIONS_HELP:
    "https://help.estrack.com/en/articles/2920734-t3-fleet-web-app-roles-and-permissions",
  T3_ADDITIONAL_DETAILS_HELP:
    "https://help.estrack.com/en/articles/2920735-updating-asset-details-and-settings",
  T3_DEVICE_INSTALL_STATUS_HELP:
    "https://help.estrack.com/en/articles/9949071-setup-page-device-install-status-help",
  TRACKER_INSTALL_GUIDE,
} as const;

export const T3_PATHNAME = {
  CREATE_ASSET: "/assets/create",
  COMPANY_ASSETS: "/assets/assets",
  CREATE_GEOFENCES: "/geofences/create",
  CREATE_USER_GROUPS: "/company-admin/groups/users",
  EDIT_ASSET: `/assets/all/asset/${STRING_REPLACE_IDENTIFIER}/edit`,
  EDIT_BRANCH: "/company-admin/branches/edit",
  USERS: "/company-admin/users/all",
} as const;
