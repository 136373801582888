import { datadogRum, RumInitConfiguration } from "@datadog/browser-rum";

import { App } from "../../types";

const datadogConfigs: Record<App, RumInitConfiguration> = {
  [App.FLEET_CONFIGURATION_DASHBOARD]: {
    applicationId: "2fab8357-08cf-490d-acac-7f651921cdbe",
    clientToken: "pub0c07a37d52e1b9fad1841e751e4f1c80",
    service: "fleet-configuration-dashboard",
  },
};

export const configureDatadogRUM = (appName: App): typeof datadogRum => {
  // Sets tags based on the environment to filter in Datadog - these should not be changed
  const datadogEnv =
    process.env.NEXT_PUBLIC_DD_ENV === "production" ? "prod" : "stage";

  const appVersion = process.env.NEXT_PUBLIC_APP_VERSION || "";

  if (!appVersion) {
    console.warn("Datadog RUM NEXT_PUBLIC_APP_VERSION is not set");
  }

  const isInitialized = !!datadogRum.getInternalContext();

  if (!isInitialized) {
    datadogRum.init({
      beforeSend(event) {
        if (
          // Silence ResizeObserver errors
          event.type === "error" &&
          event.error.message.includes("ResizeObserver")
        ) {
          return false;
        }
        return true;
      },
      ...datadogConfigs[appName],
      ...(appVersion && { version: appVersion }),
      site: "datadoghq.com",
      env: datadogEnv,
      sessionSampleRate: 100,
      sessionReplaySampleRate: 20,
      trackUserInteractions: true,
      trackResources: true,
      trackLongTasks: true,
      defaultPrivacyLevel: "mask-user-input",
    });
  }

  return datadogRum;
};
