import styled from "@emotion/styled";
import {
  EuiBadge,
  EuiFlexGroup,
  EuiFlexItem,
  EuiText,
} from "@equipmentshare/ds2";
import { DatumId } from "@nivo/pie";
import React from "react";

export type DataVizTooltipDatum = {
  color: string;
  label: DatumId;
  value: number;
  detail?: React.ReactNode;
  formattedValue?: string;
};

export type DataVizTooltipProps = {
  datum: DataVizTooltipDatum;
  hasBorder?: boolean;
  tooltipLabel?: string;
};

const DATAVIZ_TOOLTIP_GAP = "4px";

const TooltipContainer = styled.div`
  display: inline-flex;
`;

const Tooltip = styled(EuiFlexGroup)`
  margin: 0;
  padding: calc(${DATAVIZ_TOOLTIP_GAP} * 2);
  box-sizing: content-box;
  border-radius: ${DATAVIZ_TOOLTIP_GAP};
  background-color: #fff;
  border: 2px solid;
  box-shadow: 0 0 calc(${DATAVIZ_TOOLTIP_GAP} * 2) 0 rgba(0, 0, 0, 0.1);
  gap: ${DATAVIZ_TOOLTIP_GAP} !important;
`;

const TooltipLabel = styled(EuiFlexItem)`
  margin-bottom: ${DATAVIZ_TOOLTIP_GAP};
`;

const TooltipDetail = styled(EuiFlexItem)`
  max-width: 165px;
  margin-top: ${DATAVIZ_TOOLTIP_GAP};
`;

export const DataVizTooltip = (props: DataVizTooltipProps) => {
  return (
    <TooltipContainer data-testid="dataviz-tooltip-container">
      <Tooltip
        data-testid="dataviz-tooltip"
        direction="column"
        justifyContent="spaceBetween"
        style={{
          borderColor: props.hasBorder ? `${props.datum.color}` : "transparent",
        }}
      >
        {props.tooltipLabel && (
          <TooltipLabel data-testid="dataviz-tooltip-label">
            <EuiText color="subdued" size="xs">
              <p>{props.tooltipLabel}</p>
            </EuiText>
          </TooltipLabel>
        )}
        <EuiFlexGroup
          alignItems="flexStart"
          direction="row"
          justifyContent="spaceBetween"
          responsive={false}
        >
          <EuiFlexGroup
            alignItems="center"
            direction="row"
            gutterSize="s"
            responsive={false}
          >
            <EuiFlexItem grow={false}>
              <EuiBadge
                color={props.datum.color}
                style={{
                  border: "none",
                  padding: "0",
                  width: "10px",
                  height: "10px",
                }}
              />
            </EuiFlexItem>
            <EuiFlexItem grow={false}>
              <EuiText color="default" size="xs">
                <p>
                  <strong>{props.datum.label}:</strong>
                </p>
              </EuiText>
            </EuiFlexItem>
          </EuiFlexGroup>
          <EuiFlexItem data-testid="dataviz-tooltip-value">
            <EuiText color="default" size="xs" style={{ marginLeft: "auto" }}>
              <p>
                <strong>
                  {props.datum.formattedValue ?? props.datum.value}
                </strong>
              </p>
            </EuiText>
          </EuiFlexItem>
        </EuiFlexGroup>
        {props.datum.detail && (
          <TooltipDetail data-testid="dataviz-tooltip-detail">
            <EuiText color="subdued" size="xs">
              {props.datum.detail}
            </EuiText>
          </TooltipDetail>
        )}
      </Tooltip>
    </TooltipContainer>
  );
};
