import { ApolloError } from "@apollo/client";
import { isClientSide } from "@fleet-configuration/utils";

/**
 *
 * ⚠ WARNING ⚠ - This file is for CLIENT SIDE ONLY
 * Use within a server side context will result in an error
 */

/**
 * Throws an ApolloError with the provided error
 */
export const throwApolloError = (error: ApolloError) => {
  if (!isClientSide()) {
    throw new Error("throwApolloError is not available on the server side");
  }

  const graphQLErrors = error.graphQLErrors || [];
  const networkError = error.networkError;

  if (networkError) {
    console.error("Network error occurred:", networkError.message);
  }

  if (graphQLErrors.length > 0) {
    throw new ApolloError({
      graphQLErrors,
      networkError,
    });
  }
};
